@import "~@/styles/variables/variables.scss";



























.alarm-deal {
	height: 100%;
}
